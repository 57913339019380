import * as React from 'react'
import { Cell, Row } from '@toasttab/buffet-pui-table'
import { RatesBreakdown } from '../RatesBreakdown'
import { ExpandIcon } from '@toasttab/buffet-pui-icons'
import { CardTransactionFees } from '@local/merchant-pricing-viewer'

export interface PricingTemplatesRowProps {
  testId?: string | number
  rowData: {
    id: string
    version: string
    type: string
    description: string
    channel: string
    currency: string
    model: string
  }
  rates: CardTransactionFees[]
}

/**
 * PricingTemplatesRow component
 */
export const PricingTemplatesRow = (props: PricingTemplatesRowProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <>
      <Row
        style={{
          backgroundColor: 'white'
        }}
        testId={`pricing-template-row-${props.rowData.id}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Cell>
          <ExpandIcon
            isExpanded={isExpanded}
            className='text-secondary'
            aria-label={isExpanded ? 'close' : 'open'}
          />
          {props.rowData.id}
        </Cell>
        <Cell>{props.rowData.version}</Cell>
        <Cell>{props.rowData.type}</Cell>
        <Cell>{props.rowData.description}</Cell>
        <Cell>{props.rowData.channel}</Cell>
        <Cell>{props.rowData.currency}</Cell>
        <Cell>{props.rowData.model}</Cell>
      </Row>
      {isExpanded && (
        <Row style={{ backgroundColor: 'white' }}>
          <Cell colSpan={7}>
            <RatesBreakdown rateInfos={props.rates} />
          </Cell>
        </Row>
      )}
    </>
  )
}
