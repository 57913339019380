import * as React from 'react'
import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'
import {
  Permissions403DesktopIllustration,
  Permissions403MobileIllustration
} from '@toasttab/buffet-pui-illustrations'

export interface NoActiveTemplateErrorProps {
  testId?: string | number
  guid: string | null
}

/**
 * NoActiveTemplateError component
 */
export const NoActiveTemplateError = (props: NoActiveTemplateErrorProps) => (
  <ErrorContainer>
    <div data-testid={props.testId}>
      <div
        className='hidden md:block visible mx-auto'
        style={{
          maxWidth: 600
        }}
      >
        <Permissions403DesktopIllustration />
      </div>
      <div
        className='block md:hidden visible mx-auto'
        style={{
          maxWidth: 250
        }}
      >
        <Permissions403MobileIllustration />
      </div>
    </div>
    <div>
      <ErrorHeading>No active pricing template was found</ErrorHeading>
      <ErrorSubheading>
        No active pricing template was found for Restaurant Guid:{' '}
        <span className='font-semibold'>{props.guid}</span>
      </ErrorSubheading>
      <ErrorSubheading>
        Please ensure the guid in the URL is correct
      </ErrorSubheading>
    </div>
  </ErrorContainer>
)
