import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  retrieveTemplateId,
  retrieveTemplate,
  retrieveSurchargeInfo
} from '../api'

/**
 * usePricing
 */
export const usePricing = () => {
  const [combinedData, setCombinedData] = React.useState<any[]>([])

  const {
    mutate,
    isLoading: isLoadingTemplateId,
    isError: isErrorTemplateId,
    error: retrieveTemplateError
  } = useMutation(retrieveTemplateId, {
    onSuccess: (data) => {
      data.data.templateIds.forEach((id: string) => {
        getTemplate({ templateId: id })
      })
    }
  })

  const {
    data: surchargeInfo,
    mutate: getSurchargingInfo,
    isLoading: isLoadingSurchargeInfo,
    isError: isErrorSurchargingInfo,
    error: surchargeInfoError
  } = useMutation(retrieveSurchargeInfo)

  const {
    mutate: getTemplate,
    isLoading: isLoadingTemplate,
    isError: isErrorTemplate
  } = useMutation(retrieveTemplate, {
    onSuccess: (data) => {
      setCombinedData([...combinedData, data.data.pricingTemplates[0]])
    }
  })

  const isLoading =
    isLoadingTemplateId || isLoadingTemplate || isLoadingSurchargeInfo
  const isError = isErrorTemplateId || isErrorTemplate || isErrorSurchargingInfo

  return {
    combinedData,
    surchargeInfo,
    isLoading,
    isError,
    retrieveTemplateError,
    surchargeInfoError,
    mutate,
    getSurchargingInfo
  }
}
