import { SurchargeConfigSettings, usePricing } from '..'
import { PricingTemplatesTable } from '@local/pricing-templates'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { ErrorPage500, ErrorPage403 } from '@toasttab/buffet-pui-error-pages'
import { MerryGoRound, SIZE } from '@toasttab/buffet-pui-loading-indicators'
import * as React from 'react'
import { checkFor404, checkFor403 } from './ErrorResponseUtilities'
import { AxiosError } from 'axios'
import { NoActiveTemplateError } from '../NoActiveTemplateError'

export interface MerchantPricingViewerPageProps {
  testId?: string | number
}

export type CardTransactionFees = {
  name: string
  brand: string
  funding: string
  channelClassification: string
  percentFee: number
  flatFee: number
}

export type PricingData = {
  versionedId: string
  id: string
  version: string
  type: string
  description: string
  channel: string
  currency: string
  model: string
  paymentMethod: string
  criteria: {
    gpvRange: string
    labels: string[]
    salesChannels: string[]
  }
  cardTransactionFees: CardTransactionFees[]
}

const retrieveGuidFromUrlIfPresent = () => {
  const guidRegex =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/

  if (guidRegex.test(window.location.pathname)) {
    const guidArray = guidRegex.exec(window.location.pathname)
    return guidArray != null ? guidArray[0] : null
  } else return null
}

/**
 * MerchantPricingViewerPage component
 */
export const MerchantPricingViewerPage = (
  props: MerchantPricingViewerPageProps
) => {
  const {
    combinedData,
    surchargeInfo,
    isLoading,
    isError,
    mutate,
    getSurchargingInfo,
    retrieveTemplateError,
    surchargeInfoError
  } = usePricing()

  const [guid] = React.useState(() => retrieveGuidFromUrlIfPresent())

  React.useEffect(() => {
    if (guid) {
      mutate({ restaurantGuid: guid })
      getSurchargingInfo({ restaurantGuid: guid })
    }
  }, [mutate, guid, getSurchargingInfo])

  const [is404, setIs404] = React.useState({
    template: false,
    surcharge: false
  })

  React.useEffect(() => {
    if (isError) {
      setIs404({
        template: checkFor404(retrieveTemplateError as AxiosError),
        surcharge: checkFor404(surchargeInfoError as AxiosError)
      })
    }
  }, [isError, retrieveTemplateError, surchargeInfoError])

  const checkError = () => {
    if (isError && !is404.surcharge && !is404.template) {
      return checkFor403(retrieveTemplateError as AxiosError) ? (
        <ErrorPage403 />
      ) : (
        <ErrorPage500 />
      )
    }
    return (
      <div data-testid={props.testId}>
        <CardContainer className='h-screen'>
          {isLoading ? (
            <div
              className='w-full flex justify-center items-center'
              style={{ minHeight: '551px' }}
            >
              <MerryGoRound size={SIZE.xl} />
            </div>
          ) : (
            <>
              <p className='type-headline-4 font-bold'>Merchant pricing info</p>
              {!is404.surcharge && (
                <SurchargeConfigSettings data={surchargeInfo?.data} />
              )}
              {is404.template ? (
                <NoActiveTemplateError guid={guid} />
              ) : (
                <PricingTemplatesTable tableData={combinedData} />
              )}
            </>
          )}
        </CardContainer>
      </div>
    )
  }

  return <>{checkError()}</>
}
