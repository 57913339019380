import * as React from 'react'
import { PricingData } from '@local/merchant-pricing-viewer'
import { Body, Head, HeadingCell, Row, Table } from '@toasttab/buffet-pui-table'
import { PricingTemplatesRow } from '..'

export interface PricingTemplatesTableProps {
  testId?: string | number
  tableData: PricingData[]
}

/**
 * PricingTemplatesTable component
 */
export const PricingTemplatesTable = (props: PricingTemplatesTableProps) => {
  return (
    <div data-testid={props.testId}>
      <Table>
        <Head>
          <Row>
            <HeadingCell>Template ID</HeadingCell>
            <HeadingCell>Version</HeadingCell>
            <HeadingCell>Type</HeadingCell>
            <HeadingCell>Description</HeadingCell>
            <HeadingCell>Channel</HeadingCell>
            <HeadingCell>Currency</HeadingCell>
            <HeadingCell>Model</HeadingCell>
          </Row>
        </Head>
        <Body>
          {props.tableData &&
            props.tableData.map((obj) => {
              return (
                <PricingTemplatesRow
                  key={obj.id + obj.version}
                  rowData={obj}
                  rates={obj.cardTransactionFees}
                />
              )
            })}
        </Body>
      </Table>
    </div>
  )
}
