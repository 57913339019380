import { Alert } from '@toasttab/buffet-pui-alerts'
import { CardContainer } from '@toasttab/buffet-pui-card'
import * as React from 'react'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'

export interface SurchargeConfigSettingsProps {
  testId?: string | number
  data: SurchargeInfo
}

interface SurchargeDetails {
  surchargePercent: string
  enabled: boolean
  taxable: boolean
  createdDate: string
  taxRateGuids: string[]
}

interface SurchargeOnboardingDetails {
  surchargePercent: number
  taxRateGuids: string[]
  createdDate: string
  minimumStartDate: string
  startDate: string
  surchargingOnboardingStatus: string
  pricingTemplateIds: string[]
  pricingTemplateUpdateStatus: string
  lastModifiedDate: string
  lastModifiedUserGuid: string
  userGuidAtTimeOfAcceptance: string
  dateAtTimeOfAcceptance: string
  surchargeTermsAndConditionsAcceptedUrl: string
  pricingTemplateIdsAccepted: string[]
}

interface SurchargeInfo {
  onboardingDetails: SurchargeOnboardingDetails
  currentDetails?: SurchargeDetails
}

/**
 * SurchargeConfigSettings component
 */
export const SurchargeConfigSettings = (
  props: SurchargeConfigSettingsProps
) => {
  const isInWaitingPeriod = props.data?.currentDetails === null
  const minStartDate = new Date(props.data?.onboardingDetails.minimumStartDate)
  const showMerchantEarliestSignUpAlert =
    isInWaitingPeriod &&
    props.data.onboardingDetails.surchargePercent === null &&
    minStartDate > new Date()

  // eslint-disable-next-line @toasttab/buffet/date-formats
  const formatDate = (date: Date) => format(date, Formats.date.long, 'en-US')

  return (
    <CardContainer data-testid={props.testId} className='mb-8 mt-4'>
      <p className='type-headline-5 font-semibold mb-4'>
        Surcharging configuration
      </p>
      {showMerchantEarliestSignUpAlert && (
        <Alert outlined className='mb-6 mt-2' variant='info'>
          If merchant signs-up today, the earliest they can surcharge is{' '}
          <strong>{formatDate(minStartDate)}</strong>
        </Alert>
      )}
      {props.data && (
        <div className='type-subhead text-secondary flex'>
          <div className='w-1/2'>
            <p className='mb-2'>
              User guid at time of acceptance:{' '}
              <span className='font-semibold'>
                {props.data.onboardingDetails.userGuidAtTimeOfAcceptance}
              </span>
            </p>
            <p className='mb-2'>
              Date at time of Acceptance:{' '}
              <span className='font-semibold'>
                {props.data.onboardingDetails.dateAtTimeOfAcceptance}
              </span>
            </p>
            <p className='mb-2'>
              Surcharge T&Cs accepted URL:{' '}
              {props.data.onboardingDetails
                .surchargeTermsAndConditionsAcceptedUrl && (
                <a
                  className='inline-link'
                  href={
                    props.data.onboardingDetails
                      .surchargeTermsAndConditionsAcceptedUrl
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  link
                </a>
              )}
            </p>
          </div>
          <div>
            <p className='mb-2'>
              Enabled:{' '}
              <span className='font-semibold'>
                {isInWaitingPeriod
                  ? false.toString()
                  : props.data.currentDetails?.enabled?.toString()}
              </span>
            </p>
            <p className='mb-2'>
              Surcharge rate:{' '}
              <span className='font-semibold'>
                {isInWaitingPeriod
                  ? props.data.onboardingDetails.surchargePercent
                  : props.data.currentDetails?.surchargePercent}
              </span>
            </p>
            <p className='mb-2'>
              Go live date:{' '}
              <span className='font-semibold'>
                {props.data.onboardingDetails.startDate}
              </span>
            </p>
            <p className='mb-2'>
              Pricing Template IDs Accepted:{' '}
              <span className='font-semibold'>
                {props.data.onboardingDetails.pricingTemplateIdsAccepted}
              </span>
            </p>
          </div>
        </div>
      )}
    </CardContainer>
  )
}
