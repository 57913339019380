import axios from 'axios'

export const retrieveTemplateId = (props: { restaurantGuid: string }) => {
  return axios
    .create({
      baseURL: '/api/service/cc-pricing-engine/v1/merchant-pricing-templates/'
    })
    .get(`/${props.restaurantGuid}`)
}

export const retrieveTemplate = (props: { templateId: string }) => {
  return axios
    .create({
      baseURL: '/api/service/pricing-schedule/v1/pricingtemplates'
    })
    .get(`?latest_version=true&id=${props.templateId}&GPV=ANY&status=APPROVED`)
}

export const retrieveSurchargeInfo = (props: { restaurantGuid: string }) => {
  return axios
    .create({
      baseURL: '/api/service/pricing-schedule/v1/surcharging-onboarding-state/'
    })
    .get(`/${props.restaurantGuid}`)
}
