import * as React from 'react'
import { MerchantPricingViewerPage } from '@local/merchant-pricing-viewer'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <div className='type-default' data-testid='app-asdf'>
        <MerchantPricingViewerPage />
      </div>
    </QueryClientProvider>
  )
}
