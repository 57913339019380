import * as React from 'react'
import { CardTransactionFees } from '@local/merchant-pricing-viewer'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { formatCents, formatPercent } from '../utils/formattingUtilities'

export interface RatesBreakdownProps {
  testId?: string | number
  rateInfos: CardTransactionFees[]
}

/**
 * RatesBreakdown component
 */
export const RatesBreakdown = (props: RatesBreakdownProps) => {
  return (
    <div data-testid={props.testId}>
      <Table>
        <Head>
          <Row>
            <HeadingCell>Name</HeadingCell>
            <HeadingCell>Brand</HeadingCell>
            <HeadingCell>Funding</HeadingCell>
            <HeadingCell>Channel</HeadingCell>
            <HeadingCell>Percent Fee</HeadingCell>
            <HeadingCell>Flat fee</HeadingCell>
          </Row>
        </Head>
        <Body>
          {props.rateInfos.map((rate) => {
            return (
              <Row
                key={rate.name + rate.flatFee}
                style={{
                  backgroundColor: '#F7F7F7',
                  borderBottom: '1px solid',
                  borderBottomColor: '#D9D9D9'
                }}
              >
                <Cell>{rate.name}</Cell>
                <Cell>{rate.brand}</Cell>
                <Cell>{rate.funding}</Cell>
                <Cell>{rate.channelClassification}</Cell>
                <Cell>{formatPercent(rate.percentFee)}</Cell>
                <Cell>{formatCents(rate.flatFee)}</Cell>
              </Row>
            )
          })}
        </Body>
      </Table>
    </div>
  )
}
